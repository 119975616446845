interface ProgressBarLoopProps {
	isNavigating: boolean;
}

export function ProgressBarLoop({
	isNavigating = false,
}: ProgressBarLoopProps) {
	if (!isNavigating) {
		return null;
	}

	return (
		<div
			className="fixed top-0 left-0 z-50 h-2 w-full overflow-hidden"
			role="progressbar"
			aria-label="Loading"
			aria-live="polite"
		>
			<div className="h-full transform animate-loop-progress bg-blue-50"></div>
		</div>
	);
}
